import React from 'react';
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const wp = (valor) => {
  return valor;
}

const hp = (valor) => {
  return valor;
}

const styles = makeStyles({
  overlay: {},
  container: {
    backgroundColor: '#F5F6FA',
  },
  layoutLogin: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    width: wp('80%'),
    height: hp('50%'),
  },
  logoImg: {
    width: wp('60%'),
    height: hp('30%'),
    marginTop: 30,
    justifyContent: 'center',
    resizeMode: 'contain',
  },
  input: {
    marginVertical: 8,
  },
  loginButton: {
    margin: 20,
    backgroundColor: '#CF1518',
    width: wp('60%'),
    height: hp('7%'),
  },
  labelButton: {
    color: '#FFFFFF',
  },
  checkboxContainer: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    marginTop: 5,
    marginBottom: 10,
  },
  spinnerTextStyle: {},
});


export function Loading({ loading, error }) {
  return (
    <Box style={styles.overlay}>
      <Box>
        <CircularProgress
        style={{display: 'none'}}
          //cancelable={true}
          animation="fade"
          //visible={loading}
          //textContent={'Sincronizando banco de dados...'}
          //textStyle={styles.spinnerTextStyle}
          //color={colors.primary}
          //overlayColor={colors.white}
          size="large"
        />
        {/* {error !== '' && (
          <Text style={{ fontSize: 18, color: 'red' }}>error</Text>
        )} */}
      </Box>
    </Box>
  );
}