import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GeocadMap from '../../components/map'
import Spinner from '../../components/Spinner'
import style from '../../themes/styles/geral';
import MyLocation from "@material-ui/icons/MyLocation";
import MenuSharp from '@material-ui/icons/MenuSharp';
import ArrowBack from "@material-ui/icons/ArrowBack";
import WbAuto from "@material-ui/icons/WbAuto";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Save from "@material-ui/icons/Save";
import { Row } from 'react-bootstrap';
import icones from '../../themes/icones';
import {
  addCoordsToTempPolygon,
  resetTempPolygon,
  resetCurrentPolygon,
  getPolygon,
  setPolygon
} from '../../services/database.service'
import { locate, showPolygon, setNewLocation } from '../../assets/js/geocadLib';
import Button from "@material-ui/core/Button";
import {
  setMapOpen,
}
  from '../../services/database.service'

const useStyle = makeStyles(style);


export default function MapViewer(props) {
  const classes = useStyle();
  const modalOpen = true;
  const [gpsTracker, setGpsTracker] = useState(false);
  const [autoTracker, setAutoTracker] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [msgBox, setMsgBox] = useState([]);
  const [intrv, setIntrv] = useState(0);
  const { closeMap, showMsg, fillMapData } = props;

  useEffect(() => {
    setMapOpen()
    if (gpsTracker) locate();
  }, [])

  useEffect(() => {
    if (gpsTracker) {
      setIntrv(setInterval(async () => {
        markGPS({ auto: autoTracker, intrv: intrv });
        if (!autoTracker) {
          clearAllIntervals(intrv);
        }
      }, 1000));
    }
  }, [autoTracker])

  const gpsTrack = () => {
    setGpsTracker(true)
  }

  const clearAllIntervals = (count = 0) => {
    if(count < 0) return true;
    clearInterval(count);
    clearAllIntervals(count - 1);
  }

  const asyncGeoLocation = async (params = {}) => {
    let result = setNewLocation({ handle: showMsg.warning, type: 'warning', ...params });
    showPolygon();
    setSpinner(false)
    showMsg[result.success](result.msg);
  }

  const handleCloseMap = () => {
    closeMap();
    if(fillMapData) fillMapData();
    setMapOpen(false);
    clearAllIntervals(intrv);
  }

  const save = () => {
    setPolygon('sdfsdgsdg');
    // if(!getPolygon()) return showMsg.error('Sem dados para armazenar!');
    handleCloseMap();
  }

  const cancel = () => {
    resetCurrentPolygon();
    handleCloseMap();
  }

  const stopGPSTracking = () => {
    let result = showPolygon();
    console.log('result', result);
    if (Array.isArray(result?.coords) && autoTracker) {
      let msg = ['Coordenadas'];
      result.coords[0].map((res) => {
        msg.push(`[${res[0]}, ${res[1]}]`);
      })
      console.log('res', msg);
      setMsgBox(msg);
    }

    clearAllIntervals(intrv);
    setGpsTracker(false);
  }

  const markGPS = async (params = {}) => {
    setSpinner(true)
    await asyncGeoLocation(params);
  }

  const handleGpsMarker = () => {
    return gpsTracker && !autoTracker ?
      <Row className={`${classes.gpsTrackViewer} d-flex position-absolute w-100 p-0 m-0`} >
        <Box className={` position-relative w-100 d-flex flex-column justify-content-start end-0 h-100 p-0`}>

          <Button onClick={() => markGPS()} className={`${classes.gpsTrackButton} `} >
            marcar
          </Button>

          <Button onClick={() => stopGPSTracking()} className={`${classes.gpsTrackButton} `} >
            sair
          </Button>

        </Box>
      </Row> : null
  }

  const feedbackGps = () => {
    return msgBox.length > 0 ?
      <Row className={`${classes.gpsTrackViewerMsg} d-flex flex-column justify-content-start position-absolute w-100 h-100 p-0 m-0`} >

        {msgBox.map((msg) => {
          return (
            <span key={new Date().getTime()}>
              {msg}
            </span>
          )
        })}

        <Button onClick={() => { setMsgBox([]) }} className={`${classes.gpsTrackButtonMsg} `} >
          ok
        </Button>

      </Row> : null
  }

  const menu = () => {
    return (
      <>
        <Box className={`${classes.mapViewerVerticalMenuItem} d-flex w-100 end-0`} onClick={() => save()}>
          <Save disabled={false} onClick={() => save()}
            className={`${classes.topIcon} ${classes.black} p-0`}
            src={icones.remove} />Salvar
        </Box>

        {autoTracker ?
          <Box className={`${classes.mapViewerVerticalMenuItem} d-flex w-100 end-0`} onClick={() => { setGpsTracker(false); stopGPSTracking() }}>
            <HighlightOff disabled={false} onClick={() => { setGpsTracker(false); stopGPSTracking() }}
              className={`${classes.topIcon} ${classes.red} p-0`}
              src={icones.remove} />Parar Tracking
          </Box> : null}

        <Box className={`${classes.mapViewerVerticalMenuItem} d-flex w-100 end-0`} onClick={() => { setGpsTracker(true); setAutoTracker(true) }}>
          <WbAuto disabled={false} onClick={() => { setGpsTracker(true); setAutoTracker(true) }}
            className={`${classes.topIcon} ${classes.black} p-0`}
            src={icones.remove} />Tracking Auto
        </Box>

        <Box className={`${classes.mapViewerVerticalMenuItem} d-flex w-100 end-0`} onClick={() => gpsTrack()}>
          <MyLocation disabled={false} onClick={() => gpsTrack()}
            className={`${classes.topIcon} ${classes.blue} p-0`}
            src={icones.remove} />Tracking Manual
        </Box>

        <Box className={`${classes.mapViewerVerticalMenuItem} d-flex w-100 end-0`} onClick={() => cancel()}>
          <ArrowBack disabled={false} onClick={() => cancel()}
            className={`${classes.topIcon} ${classes.red} p-0`}
            src={icones.remove} />Sair
        </Box>
      </>
    )
  }

  return (
    <Row className={`${classes.mapViewer} position-absolute d-flex flex-row-reverse justify-content-end w-100 vh-100 p-0 m-0 d-flex flex-column `} >
      {spinner ? <Spinner setShow={setSpinner} /> : null}
      <Row className={`${classes.mapViewerHead} position-absolute w-100 p-0 m-0`} >

        <Box className={`${classes.mapViewerVerticalMenu} d-flex end-0 p-1`}>

          {showMenu ? menu() : null}

          <Box className={`${classes.mapViewerVerticalMenuItem} d-flex flex-row-reverse justify-content-start w-auto end-0`} onClick={() => setShowMenu(!showMenu)}>
            <MenuSharp disabled={false} onClick={() => setShowMenu(!showMenu)}
              className={`${classes.topIcon} ${classes.blue} p-0`}
              src={icones.remove} />
          </Box>
        </Box>
      </Row>

      {modalOpen ? <Row className={`${classes.mapViewerBody} w-100 p-0 m-0 `} >
        <div className={`${classes.mapViewerMap} m-0 p-0`} >
          <GeocadMap
            style={{ zIndex: 1 }}
            id={'map'}
            center={[-8.9192245, 13.1855814]}
            zoom={10}
            polygon={[[51.509, -0.08], [51.503, -0.06], [51.51, -0.047]]}
            shapefile={[]} />
        </div>
      </Row> : null}

      {handleGpsMarker()}
      {feedbackGps()}

    </Row>
  );
};