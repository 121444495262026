import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import style from '../../themes/styles/geral';
import Button from "@material-ui/core/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

const useStyle = makeStyles(style);

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function ConfirmBox(props) {
    const classes = useStyle();
    const { show = true,
        title = 'Confirmação',
        msg = 'A sua mensagem aqui.',
        handleResult,
    } = props;

    const handleNao = () => {
        if (typeof handleResult == 'function') {
            handleResult(false);
        }
    };

    const handleSim = () => {
        if (typeof handleResult == 'function') {
            handleResult(true);
        }
    };

    return (
        <div>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={show}
                onClose={handleNao}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={`${classes.cancelBtn}`} autoFocus onClick={handleNao}>
                        Não
                    </Button>
                    <Button className={`${classes.infoBtn}`} onClick={handleSim}>Sim</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}