const colors = {
  white: '#FFFFFF',
  lightGray: '#F1F2F2',
  darkGray: '#414042',
  lightRed: '#F36E34',
  darkRed: '#DD2477',
  black: '#000000',
  backgroundColor: '#F1F2F2',
  lightGreen: '#E3E422',
  darkGreen: '#63BC46',
  lightBlue: '#27BCEE',
  darkBlue: '#127DC2',
  executionStatus: '#7EBDC2',
  notStarted: '#FFED65',
  rejectedStatus: '#C43C54',
  primary: '#FF9982',
  dark: '#333',
  regular: '#666',
  light: '#C0C0C0',
  borderGray: '#F1E0E2'
};

export default colors;
