import React, { createContext, useEffect, useState } from 'react';
import * as auth from '../services/auth';
import { databaseService } from '../services/database.service';

const AuthContextData = {
  signed: '',
  user: '',
  missionsRaw: '',
  team: '',
  setTeam: '',
  defaultEmail: '',
  getDefaultEmail: '',
  headerState: '',
  setHeaderState: '',
  signIn2: null,
  db: databaseService,
  logout: () => { }
}

const AuthContext = createContext(AuthContextData);

export const AuthProvider = ({ children }) => {

  const [headerState, setHeaderState] = useState('DEFAULT');

  const [user, setUser] = useState(null);

  const [defaultEmail, setDefaultEmail] = useState(null);

  const getDefaultEmail = async function () {
    const value = '';//await AsyncStorage.getItem('@last_email');
    if (value !== 'EMPTY') {
      setDefaultEmail(value);
    }
    return value;
  };

  async function signIn2(data) {
    return await auth.signIn2(data);
  }

  async function logout() {
    setUser(null);
  }

  useEffect(() => {
    if (defaultEmail === null || !user) {
      getDefaultEmail().then((data) => {
        if (data !== 'EMPTY') {
          setDefaultEmail(data);
        }

      });
    }
  });

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user: 'nata',
        defaultEmail,
        getDefaultEmail,
        signIn2,
        logout,
        headerState,
        setHeaderState
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
