import React, { useEffect, useState } from 'react'
import { _getPosition } from '../assets/js/tracking';
import { loadMap, stopGPSTracking } from '../assets/js/geocadLib';

function GeocadMap(props) {

  const [version, setVersion] = useState(0)
  const [trackingStarted, setTrackingStarted] = useState(false)

  useEffect(() => {

    let pos = _getPosition();
    let arr = []
    if (pos) {
      arr.push(pos?.latitude)
      arr.push(pos?.longitude)
    }

    let geocadProps = {
      id: 'map' || props.center,
      center: arr.length ? arr : props.center,
      zoom: 10,
      maxZoom: 20,
      minZoom: 10,
      polygon: props.polygon,
      shapefile: props.shapefile
    };

    window.geocadProps = geocadProps
    setVersion(version + 1)

    const scriptLeaflet = document.createElement('script')
    scriptLeaflet.src = '../assets/leaflet/leaflet.js'
    scriptLeaflet.async = false
    scriptLeaflet.type = "text/javascript"
    //document.head.appendChild(scriptLeaflet)

    const scriptGeocadMapBox = document.createElement('script')
    scriptGeocadMapBox.src = '../assets/js/catiline.js'
    scriptGeocadMapBox.async = false
    //document.head.appendChild(scriptGeocadMapBox)

    const linkGeocadMapBox = document.createElement('script')
    linkGeocadMapBox.src = '../assets/js/leaflet.shpfile.js'
    scriptGeocadMapBox.async = false
    //document.head.appendChild(linkGeocadMapBox)

    const linkLeaflet = document.createElement('link')
    linkLeaflet.rel = 'stylesheet'
    linkLeaflet.href = '../assets/leaflet/leaflet.css'
    //document.head.appendChild(linkLeaflet)

    loadMap({ mapMode: 'mapbox/satellite-streets-v11', id: props.id || geocadProps.id })

  }, [])

  const startTrancking = () => {
    setTrackingStarted(true)
  }

  const stopTrancking = () => {
    setTrackingStarted(false)
    stopGPSTracking();
  }

  return (
    <>
      <div className="container">
        <div style={{ display: 'none' }} id="data_load">
          <ul>
            {trackingStarted ? <li className="btn" onClick={stopTrancking}>Parar Polígono por GPS</li> : <li className="btn" onClick={startTrancking}>Iniciar Polígono por GPS</li>}
          </ul>
        </div>

      </div>
      <div style={{ zIndex: '1' }} id="map"></div>
    </>
  )
}

export default GeocadMap