import colors from "../colors";
const headerHeight = '9%';
const boxShadow = 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset';

export default {
  container: {
    backgroundColor: colors.backgroundColor,
    margin: 0
  },
  containerM: {
    margin: 0
  },
  containerMenu: {
    top: '6%',
    height: '94%',
    zIndex: 90
  },
  menuHeader: {
    height: '6%',
  },
  box: {
    margin: '10%',
    padding: 50,
    backgroundColor: colors.backgroundColor,
    left: '25%',
    top: '25%',
    //shaddow: '2px 2px',
    boxShadow: boxShadow,
    //backgroundColor: colors.backgroundColor,
    //maxWidth: '100%',
    //flexDirection: 'row',
    //alignItems: 'center',
    //justifyContent: 'center',
    //padding: 20,
    //fontFamily: 'arial',
  },
  menuButton: {
    cursor: 'pointer',
  },
  menuLogoNoMargin: {
    cursor: 'pointer',
    display: 'inline',
    marginLeft: 5,
    width: '5%'
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  mapViewerVerticalMenu: {
    display: 'flex',
    flexDirection: 'column-reverse',
    // height: '100vh',
    width: 'auto',
    overflow: 'auto',
    opacity: '86%',
    boxShadow: `-2px 2px ${colors.darkGray}`,
    borderRadius: '5px',
    backgroundColor: colors.backgroundColor
  },
  mapViewerVerticalMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '200px',
    overflow: 'hidden',
    justifyContent: 'start',
    cursor: 'pointer',
    marginBottom: 2,
    borderBottom: `1px solid ${colors.borderGray}`,
    "&:click": {
      backgroundColor: colors.darkGray
    }
  },
  topIcon: {
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#6c757d',
    color: colors.white,
    fontWeight: 'bold',
    borderRadius: 15,
    "&:hover": {
      color: colors.white,
      backgroundColor: '#5c636a',
      borderColor: '#565e64',
    },
    width: '3rem!important'
  },
  red: {
    color: colors.darkRed
  },
  blue: {
    color: colors.darkBlue
  },
  green: {
    color: colors.darkGreen
  },
  black: {
    color: '#000'
  },
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.darkGreen,
    borderColor: '#6c757d',
    color: colors.white,
    fontWeight: 'bold',
    borderRadius: 15,
    "&:hover": {
      color: colors.white,
      backgroundColor: '#5c636a',
      borderColor: '#565e64',
    },
    width: '25%',
    height: '25px',
  },
  menuLogo2: {
    width: '40%',
    height: '5%',
  },
  topBar: {
    width: '40%',
  },
  confirmBox: {
    position: 'fixed',
    display: 'none',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#202020',
    opacity: 0.55,
    overflow: 'hidden',
    zIndex: 212,
  },
  menuButtonRight: {
    width: '5%'
  },
  menuLogo: {
    maxWidth: '20%',
    padding: '5px !important',
    objectFit: 'contain',
    /*position: 'absolute',
    top: 0,
    left: 10,
    height: '8%',
    backgroundColor: 'inherit'*/
  },
  menuContainer: {
    zIndex: 19,
    backgroundColor: colors.backgroundColor,
    bottom: 0,
    paddingTop: 30,
    overflow: 'hidden',
    width: '100%'
  },
  menuGrafismoVertical: {
    height: '100%',
    zIndex: -1,
    top: 0,
    right: 0
  },
  menuGrafismoVertical2: {
    position: 'absolute',
    right: 0,
  },
  formData: {
    position: 'relative',
    overflow: 'auto',
    backgroundColor: colors.backgroundColor,
    padding: 20,
    height: '93%'
  },
  menuSVertical: {
    //position: 'absolute',
    //width: '100%',
    zIndex: -2,
    //backgroundColor: colors.backgroundColor,
    //padding: 10,
    //top: '20%',
    //right: 0,
    //paddingTop: 60
  },
  menuButtonContainer: {
    marginLeft: '6%',
    marginBottom: '2%',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: colors.darkGray
    }
  },
  mapMenuButtonContainer: {
    marginLeft: '6%',
    marginRight: '6%',
    marginBottom: '0.5%',
    // border: '2px solid red',
    fontSize: '0.2em',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: colors.darkGray
    }
  },
  menuButtonIcon: {
    height: '10%',
    width: '10%'
  },
  mapMenuButtonIcon: {
    // height: '5%',
    width: '15%'
  },
  menuButtonAction: {
    textAlign: 'left',
    alignItems: 'left',
  },
  formsTitleContainer: {
    //marginTop: -45,
    display: 'flex',
    flexDirection: 'column'
  },
  formsTitleContainer2: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    position: 'relative',
    maxHeight: 50
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formList: {
    padding: 20,
    height: '95%',
    overflow: 'auto',
    backgroundColor: colors.backgroundColor,
  },
  mapCoverDiv: {
    zIndex: 10
  },
  formsTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 0
  },
  formListButton: {
    backgroundColor: colors.light,
    cursor: 'pointer'
  },
  formListButtonDark: {
    cursor: 'pointer',
  },
  formListText: {
    alignItems: 'left',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
    margin: 0,
    width: '3%',
    padding: 10,
  },
  formListText2: {
    alignItems: 'left',
    textAlign: 'center',
    fontSize: 12,
    margin: 0,
    width: '3%',
    padding: 10,
  },
  formListTextFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    fontWeight: 'bold',
    fontSize: 12,
    margin: 0,
    width: '140px',
    // borderTop: `1px solid ${colors.darkGray}`,
    // borderBottom: `1px solid ${colors.darkGray}`,
  },
  notification: {
    width: 100,
    height: 100,
    alignItems: 'left',
    fontWeight: 'bold',
    fontSize: 12,
    margin: 0,
    padding: 5,
    border: `1px solid ${colors.darkRed}`,
    backgroundColor: `${colors.lightRed}`,
    borderRadius: '50%'
  },
  formsSubTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    margin: 0,
    color: colors.darkGray
  },
  formContainer: {
    padding: 3,
    marginTop: 100,
    height: 'auto',
    minHeight: '90%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: colors.backgroundColor,
  },
  error: {
    backgroundColor: 'red',
  },
  mapContainer: {
    /*flexDirection: 'column',
    alignItems: 'center',
    height: '94%',
    justifyContent: 'center',
    overflow: 'hidden'*/
  },
  mapViewer: {
    zIndex: 9000,
    backgroundColor: colors.backgroundColor,
  },
  mapViewerHead: {
    height: 'fit-content',
    maxWidth: '10%',
    right: '2%',
    top: '1%',
    zIndex: 90009,
    padding: '10%'
  },
  gpsTrackViewer: {
    height: '100%',
    zIndex: '400',
    marginTop: '40%',
    padding: '40px',
    backgroundColor: '#dadde6',
    opacity: '85%',
    boxShadow: boxShadow,
  },

  gpsTrackViewerMsg: {
    textAlign: 'center',
    zIndex: '4060',
    padding: '10%!important',
    alignItems: 'center',
    backgroundColor: colors.lightGray,
    opacity: '90%',
    boxShadow: boxShadow,
  },

  gpsTrackButtonMsg:
  {
    width: '40px',
    alignItems: 'center',
    backgroundColor: colors.backgroundColor,
    boxShadow: boxShadow,
  },
  mapViewerBody: {
    height: '100vh',
    overflow: 'hidden'
  },
  mapViewerMap: {
    height: '100vh',
  },
  header: {
    height: headerHeight,
  },
  header2: {
    height: '5%',
  },
  body: {
    height: '10%',
    overflow: 'hidden'
  },
  userContainer: {
    width: '100%',
    padding: 0
  },
  container2: {
    padding: 30,
    minWidth: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    marginTop: 15,
    marginBottom: 15,
  },
  insigneaContainer: {
    marginTop: '1%',
    padding: 10,
    maxWidth: '100%',
    justifyContent: 'space-between !important',

  },
  col3: {
    position: 'relative'
  },
  modalContainer: {
    padding: 90
  },
  logosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImg: {
    // marginTop: '10%',
    width: '60%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    resizeMode: 'contain',

  },
  tisLogo: {
    marginTop: '70%'
  },
  minagripS: {
    objectFit: 'fill',
    opacity: '20%'
  },
  minagripSContainer: {
    zIndex: 200
  },
  copyrightContainer: {
    paddingTop: 15,
    margin: 35,
    fontSize: '0.7rem'
  },
  checkedIcon: {
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    resizeMode: 'contain'
  },
  gepeImg: {
    maxWidth: '30%',
    height: '50px',

  },
  minagripImg: {
    maxWidth: '30%',
    height: '50px',

  },
  insigneaImg: {
    maxWidth: '30%',
    height: '50px',

  },
  minagripImg2: {
    // marginBottom: '20px',
  },
  loginContainer: {
    zIndex: 1,
    marginTop: 120
  },
  headerLogo: {
    width: '100%',
  },
  grafismoBaixoImg: {
    //width: '100%'
  },
  input: {
    backgroundColor: 'transparent',
    width: 'auto',
    fontSize: 15,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    verticalAlign: 'center',
    marginLeft: '25%'
  },
  inputLabel: {
    width: '25%'
  },
  inputLabel100: {
    width: '100%'
  },
  inputContainer: {
    marginTop: 15,
    fontSize: 15,
    borderBottom: `1px solid ${colors.lightBlue}`,
    verticalAlign: 'middle',
    textAlign: 'center',
    width: '70%',
  },
  forgotPassContainer: {
    marginTop: 10,
    width: '80%',
    fontSize: 12,
    verticalAlign: 'middle'
  },
  loginButton: {
    color: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundImage: `linear-gradient(180deg, ${colors.lightGreen}, ${colors.darkGreen}, ${colors.darkGreen})`,
    backgroundColor: '#198754',
    borderColor: '#198754',
    fontWeight: 'bold',
    width: '80%',
    height: '7%',
    borderRadius: 15,
    "&:hover": {
      backgroundColor: '#157347',
      borderColor: '#146c43',
    },
  },
  cancelBtn: {
    color: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.darkRed,
    borderColor: '#198754',
    fontWeight: 'bold',
    width: '10%',
    height: '7%',
    borderRadius: 10,
  },
  infoBtn: {
    color: colors.darkBlue,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: colors.darkGreen,
    borderColor: '#198754',
    fontWeight: 'bold',
    width: '10%',
    height: '7%',
    borderRadius: 10,
  },
  loginButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    verticalAlign: 'center'
  },
  loginButtonContainer2: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    verticalAlign: 'center'
  },
  loginButtonContainer3: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    verticalAlign: 'center'
  },
  voltarButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#6c757d',
    borderColor: '#6c757d',
    color: colors.white,
    fontWeight: 'bold',
    width: '40%',
    height: '7%',
    borderRadius: 15,
    "&:hover": {
      color: colors.white,
      backgroundColor: '#5c636a',
      borderColor: '#565e64',
    },
  },
  gpsTrackButton: {
    border: '5px solid blue',
    marginTop: '10%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#6c757d',
    borderColor: '#6c757d',
    color: colors.white,
    fontWeight: 'bold',
    fontSize: '30px',
    width: '40%',
    height: '5%',
    borderRadius: 15,
    "&:hover": {
      color: colors.white,
      backgroundColor: '#516496',
      borderColor: '#565e64',
    },
  },
  _cancelarButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(180deg, ${colors.lightGray}, ${colors.darkGray}, ${colors.lightGray})`,
    color: colors.white,
    fontWeight: 'bold',
    width: '100%',
    height: '17%',
    borderRadius: 2
  },
  qrCodeReader: {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(180deg, ${colors.red}, ${colors.darkGray}, ${colors.darkGray})`,
    border: `2px solid ${colors.black}`,
    borderRadius: 15,
    padding: 10
  },
  saveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    // backgroundImage: `linear-gradient(180deg, ${colors.lightGreen}, ${colors.darkGreen}, ${colors.darkGreen})`,
    backgroundColor: '#198754',
    borderColor: '#198754',
    fontWeight: 'bold',
    width: '40%',
    height: '7%',
    borderRadius: 15,
    "&:hover": {
      backgroundColor: '#157347',
      borderColor: '#146c43',
    },
  },
  voltarButtonContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    minHeight: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    zIndex: 14,
    padding: 5
  },
  voltarButtonContainerCurtain: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    minHeight: '6%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    fontSize: 14,
    backgroundColor: 'black',
    opacity: '35%',
    "& button": {
      marginLeft: 10
    },
  },
  backButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '7%',
    borderRadius: 15,
  },
  checkboxContainer: {
    display: 'none',
    // display: 'flex',
    flexDirection: 'row',
    marginTop: '5%',
    alignItems: 'left',
    height: '25px',
    fontSize: 14,
    verticalAlign: 'center'
  },
  spinnerCurtainOn: {
    position: 'absolute',
    left: 0,
    zIndex: 9000,
    paddingTop: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    height: '100vh',
    alignItems: 'center',
    verticalAlign: 'center',
    "& * ": {
      marginTop: 15
    },
  },
  spinnerCurtainOff: {
    position: 'absolute',
    left: 0,
    zIndex: 9000,
    paddingTop: '50%',
    display: 'none',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    height: '100vh',
    alignItems: 'center',
    verticalAlign: 'center',
    "& * ": {
      marginTop: 15
    },
  },
  checkboxLabel: {
    marginLeft: '1%',
    marginTop: 0
  }
};
