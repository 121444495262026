import React, { Component } from 'react';
import './App.css';
import HomeScreen from './pages/Home';
import { AuthProvider } from './contexts/auth';

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HomeScreen />
      </AuthProvider>
    );
  }
}
export default App;