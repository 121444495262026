import React, {
  useState,
  useEffect,
} from 'react';
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
import { addFormData } from '../../services/database.service'
import { formBuilderApiService } from '../../services/form_builder_api'
import { userApiService } from '../../services/api'
import { getFormList } from '../../services/database.service'
import { makeStyles } from '@material-ui/core/styles';
import style from '../../themes/styles/geral';
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import fundo_base_vertical from '../../assets/images/fundo_base-vertical.png';
import s_image from '../../assets/images/s30.png';
import {
  resetCurrentPolygon
}
  from '../../services/database.service';

const useStyle = makeStyles(style);
const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1009;
  border-color: red;
`;

export default function Formularios(props) {
  const classes = useStyle();
  const [formList, setFormList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [formSubmit, setFormSubmit] = useState();
  const [currentForm, setCurrentForm] = useState({})
  const { updateMenu, openMap, showMsg, logged, handleClose, currentData, setCurrentMapButton } = props;

  let [loading, setLoading] = useState(true);
  let color = "#d0d6d6";

  useEffect(function () {
    getForms();
  }, [])

  async function holdFile({ type = 'script', href = null, src = null, async = true, rel = null }) {

    let full_address = formBuilderApiService.getFullAdress(src || href);
    let query = `${type}[${src ? 'src' : 'href'}="${full_address}"]`
    let script_exists = document.querySelector(query)

    if (!script_exists) {
      let _element = document.createElement(type);
      if (src) _element.src = full_address;
      if (href) _element.href = full_address;
      if (async) _element.async = async;
      if (rel) _element.rel = rel;
      _element.crossorigin = "*";
      document.head.appendChild(_element);
    } else {
      if (src) script_exists.src = full_address;
      if (href) script_exists.href = full_address;
      if (async) script_exists.async = async;
      if (rel) script_exists.rel = rel;
      script_exists.crossorigin = "*";
    }
  }

  async function holdManyFiles({ files, type, async, rel }) {
    if (!files || files === undefined) return false;
    if (Array.isArray(files)) {
      files.map((file) => (
        holdFile({
          src: type.toLowerCase() === 'script' ? file : false,
          type,
          async,
          rel,
          href: type.toLowerCase() === 'link' ? file : false
        })
      ))
    }
  }

  async function getForms() {
    if (currentData) {
      handleEdit(currentData);
      return fillFields();
    }

    let response = getFormList();
    let list = [];

    if (response) {
      response = response.map(form => (
        list.push({
          name: form.nome?.toString().toUpperCase().split('.')[0],
          onclick: async () => {
            handleEdit(form);
          }
        })
      ))
    }

    setLoading(false);
    setFormList(list);
  }

  const handleEdit = async (form) => {
    let formName = form.nome
    setCurrentForm(form)
    let resp = await userApiService.getForm(form.modelo);

    holdManyFiles({ files: resp.js, src: true, type: 'script' });
    holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet' });
    holdManyFiles({ files: resp.img, src: true, type: 'img' });
    let div = document.createElement('div');
    div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
    div.style.margin = '5px';

    let btn = div?.querySelector('button[type="submit"]');
    if (!btn) {
      btn = div?.querySelector('button[onclick="formSubmit()"]');
    }

    if (!btn) {
      btn = div?.querySelector('button[onclick="formSumit()"]');
    }

    setFormSubmit(btn);
    if (btn) btn.style.display = 'none';

    let html = div.innerHTML

    setIframeUrl(html)
    setIframeTitle(formName)
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  const closeEditMode = () => {
    try {
      setIframeUrl(null)
      handleClose('formlist');
    } catch (error) {

    }
  }

  const fillFields = () => {
    let interval = setInterval(function () {
      let btn = document.querySelector('.formNewLine');
      if (btn) {
        try {
          if (!currentData) return false;
          let data = currentData.data;
          Object.keys(data).map(key => {
            let inputs = document.querySelectorAll(`input.databaseField[data-model="${key}"]:not(.notDataFieldConsider), textarea.databaseField[data-model="${key}"]:not(.notDataFieldConsider), select.databaseField[data-model="${key}"]:not(.notDataFieldConsider)`);
            Array.from(inputs).map(input => {
              try {
                if (input) {
                  let value = data[key][input.name];
                  if (input.dataset.multiple) {
                    value = data[key][input.name].values[0];
                    data[key][input.name].values = data[key][input.name].values.slice(1, data[key][input.name].values.length);
                  }

                  if (input.type === 'checkbox') {
                    if (value.includes(input.value))
                      input.checked = true;
                  } else if (input.type === 'radio' && input.dataset.value === value) {
                    input.checked = true;
                  } else if (input.type === 'file') {
                    // console.log('file')
                    // console.log(value)
                  } else {
                    input.value = value;
                  }
                }
              } catch (error) {
                console.error(error);
              }
              return true;
            });
            return true;
          })
        } catch (error) {

        }
        setLoading(false);
        clearInterval(interval);
      }
    }, 3000)

  }

  hideSpinner();

  const saveForm = () => {
    try {
      formSubmit.click();

      if (!window.formData && !currentData) return false;
      let formUrl = document.getElementById('formUrl').innerHTML;
      let data = window.formData?.content

      const dataToSave = {
        key: currentData?.key,
        form: formUrl,
        content: {
          _data: {
            ...data,
            _Metadata: {
              dataType: "inquiry",
              codigo: logged?.codigo,
              name: logged?.nome,
              filledForm: formUrl,
              epocaCodigo: logged.epoca?.codigo,
              formulario: currentForm?.nome,
              createdAt: new Date(),
            }
          }
        }
      }

      let response = addFormData(dataToSave);
      if (response) {
        setIframeUrl(null);
        resetCurrentPolygon();
        showMsg.success('Dados salvos com sucesso!')
      }
    } catch (error) {
      showMsg.success('Erro ao salvar dados!')
      console.log(error)
    }
  }

  const handleButtons = (saveButton = false) => {
    return (
      <>
        <Box className={`${classes.voltarButtonContainerCurtain}`} ></Box>
        <Box className={`${classes.voltarButtonContainer}`} >
          <Button onClick={() => closeEditMode()} className={`${classes.voltarButton} `} >
            Voltar
          </Button>
          <Button onClick={() => saveForm()} className={`${classes.saveButton}`} >
            Salvar
          </Button>
        </Box>
      </>
    );
  }

  const handleTitle = (params) => {
    const { title = 'Formulários', subtitle = 'Cadastrados' } = params ? params : {};
    return (<Box className={`${classes.formsTitleContainer} position-relative`} >

      <h6 className={classes.formsTitle}>{title}</h6>
      <label className={classes.formsSubTitle}>{subtitle}</label>
    </Box>);
  }

  const handleFormMenus = () => {
    return (
      <Box className={`${classes.menuContainer} h-100 w-100 p-0 m-0`} >
        <Box className={`${loading ? classes.spinnerCurtainOn : classes.spinnerCurtainOff} h-100 w-100`} >
          <RingLoader color={color} loading={loading} css={override} size={600} />
        </Box>
        <img
          className={`${classes.menuGrafismoVertical} d-none`}
          src={fundo_base_vertical}
          alt=''
        />
        <img
          className={`${classes.menuSVertical} d-none`}
          src={s_image}
          alt=''
        />

        {iframeUrl ?
          <>
            <Box className={`${classes.formData} d--flex flex-column w-100 m-0`} >
              {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
              <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} updateMenu={updateMenu} setCurrentMapButton={setCurrentMapButton} />
            </Box>
            {handleButtons(true)}
          </>
          :
          <>
            <Box className={`${classes.formList} flex-fill`} >
              {handleTitle()}
              <Table>
                <TableBody>
                  {formList.map((menu, key) => {

                    return (
                      <TableRow key={key} onClick={menu.onclick} className={key % 2 === 0 ? classes.formListButtonDark : classes.formListButton} >
                        <TableCell className={classes.formListText} >{menu.name}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </>
        }
      </Box>)
  }

  return (
    handleFormMenus()
  );
};