import home from '../assets/images/icones/azul-HOME.png';
import minha_conta from '../assets/images/icones/MINHA CONTA.png';
import formularios from '../assets/images/icones/azul-FORMULARIOS.png';
import sincronizacao from '../assets/images/icones/SINCRONIZAÇÃO.png';
import sincronizacao_all from '../assets/images/icones/sincronize_all.png';
import logout from '../assets/images/icones/LOGOUT.png';
import desenhar from '../assets/images/icones/desenhar.png';
import gps from '../assets/images/icones/gps.png';
import salvar from '../assets/images/icones/salvar.png';
import mapa from '../assets/images/icones/mapa.png';
import remove from '../assets/images/icones/remove.png';

const icones = {
  home: home,
  minha_conta: minha_conta,
  formularios: formularios,
  sincronizacao: sincronizacao,
  sincronizacao_all: sincronizacao_all,
  logout: logout,
  desenhar: desenhar,
  gps: gps,
  salvar: salvar,
  mapa: mapa,
  remove: remove,
};

export default icones;
