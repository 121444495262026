import axios from 'axios';
import { databaseService } from './database.service';

const BASE_URL = process?.env.REACT_APP_FORM_BUILDER_HOST;
const BASE_FRONTEND_URL = process?.env.REACT_APP_FORM_BUILDER_HOST;

axios.defaults.baseURL = BASE_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  mode: 'no-cors'
});

const axios_config = {
  headers: {
    'Content-Type': 'text/json'
  }
}

const getAll = async () => {
  try {
    let response = await httpClient.get('/form-list');
    if (response.data) {
      let result = [];
      for (let index in response.data.files) {
        result.push({ name: response.data.files[index], content: await getForm(response.data.files[index]) });
      }
      return result;
    }
  } catch (error) {

  }
  return [];
};

const getForm = async (nome) => {
  try {
    // let response = await httpClient.get(`/get-form/Salvar.html`);
    let response = await httpClient.get(`/get-form/${nome}`, axios_config);
    if (response.data) {
      response.data.baseURL = BASE_URL;
      return response.data;
    }
  } catch (error) {

  }
  return [];
};

const getFullAdress = (name) => {
  return `${BASE_FRONTEND_URL}${name}`;
};

const create = (data) => {
  return httpClient.post('/', data);
};

const update = (id, data) => {
  return httpClient.put(`/${id}`, data);
};

const remove = (id) => {
  return httpClient.delete(`/${id}`);
};

const removeAll = () => {
  return httpClient.delete('/');
};

const findByTitle = (title) => {
  return httpClient.get(`/?title=${title}`);
};

const synchronize = async () => {
  let formData = databaseService.getFormData();
  let resp = await httpClient.post('/saveMany', formData);
  console.log('resp', resp);
  if (resp?.data?.result) {
    databaseService.resetFormData();
    return true;
  }
  return false;
};

const synchronizeOne = async (formName, body, key) => {
  let filledForm = body._Metadata.filledForm
  let resp = await httpClient.post(`/save/${filledForm}`, body);
  console.log('resp');
  console.log(resp);

  if (resp?.data?.result) {
    return removeOne(formName, key);
  }
  return false;
};

const synchronizeMany = async (rowData) => {
  Object.values(rowData).map(async (item) => {
    if (item.checked) {
      console.log(`o checked`)
      console.log(item)
      await synchronizeOne(item.data._Metadata.filledForm, item.data, item.id);
    }
  });
};

const removeOne = (formName, key) => {
  let fullList = databaseService.getFormData();
  for (let i in fullList) {
    if (fullList[i].form === formName) {
      delete fullList[i].content[key];
      if (fullList[i].content.length <= 0) {
        delete fullList[i];
      }
      break;
    }
  }

  databaseService.resetFormData(fullList);
  return true;
};

const removeMany = (rowData) => {
  Object.values(rowData).map(async (item) => {
    if (item.checked) {
      await removeOne(item.nome, item.id);
    }
  });
}

const countSavedData = async () => {
  let formData = databaseService.getFormData();
  let total = Object.values(formData).reduce(function (sum, val) {
    return sum + Object.values(val?.content || {}).length;
  }, 0);
  return total;
};

export const formBuilderApiService = {
  getAll,
  getFullAdress,
  getForm,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  synchronize,
  synchronizeOne,
  synchronizeMany,
  removeOne,
  removeMany,
  countSavedData
};
