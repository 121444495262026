import React, {
  useState,
  useEffect,
} from 'react';

import { logout } from '../../services/database.service'
import { userApiService } from '../../services/api'
import { makeStyles } from '@material-ui/core/styles';
import style from '../../themes/styles/geral';
import icones from '../../themes/icones';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import fundo_base_vertical from '../../assets/images/fundo_base-vertical.png';
import s_image from '../../assets/images/s30.png';
import { useWindowSize } from '@react-hook/window-size';

const useStyle = makeStyles(style);

export default function Menu(props) {
  const classes = useStyle();
  const [countData, setCountData] = useState();
  const { handleClose, changeScreen, setLogged } = props;

  const menuList = [
    { show: true, icon: icones.home, title: 'Home ', class: '', onclick: () => { changeScreen(null); handleClose(); } },
    {
      show: true, icon: icones.formularios, title: 'Questionários', class: '', onclick: () => {
        changeScreen('formlist');
      }
    },
    {
      show: true, hasNotification: countData > 0, icon: icones.sincronizacao, title: 'Questionários Preenchidos', class: '', onclick: async () => {
        changeScreen('sincronizacao');
        updateMenu();
      },
      notification: countData
    },
    {
      show: true, hasNotification: false, icon: icones.mapa, title: 'Carregar Mapa', class: '', onclick: async () => {
        changeScreen('mapa');
        updateMenu();
      }
    },
    {
      show: true, icon: icones.logout, title: 'Logout', class: 'd-md-12', onclick: async () => {
        try {
          logout(); 
          setLogged(false);
        } catch (error) {

        }
          window.location.reload();
      }
    }
  ]

  useEffect(function () {

  }, [])

  async function updateMenu() {
    let count = await userApiService.countSavedData();
    setCountData(count);
  }

  updateMenu()

  const showMenu = () => {
    return (
      <Box className={`${classes.menuContainer} position-relative vh-100 start-0 `}  >
        <img
          className={`${classes.menuGrafismoVertical} ${classes.menuGrafismoVertical2}`}
          src={fundo_base_vertical}
          alt=''
        />
        <img
          className={`${classes.menuSVertical} mw-100 mh-100 position-absolute end-0`}
          src={s_image}
          alt=''
        />
        {menuList.map((menu, key) => {
          return menu.show ? (
            <Box key={key} onClick={menu.onclick} className={`${classes.menuButtonContainer} ${menu.class}`} >
              <img
                className={classes.menuButtonIcon}
                src={menu.icon}
                alt=''
              />
              <Button className={`${classes.menuButtonAction}`} >
                {menu.title}
              </Button>
              {menu.hasNotification ?
                (<span className={`${classes.notification}`} >
                  {countData}
                </span>) : null}
            </Box>
          ) : null
        })}
      </Box>);
  }

  return (
    <>
      {showMenu()}
    </>
  );
};