import axios from 'axios';
import { formBuilderApiService } from './form_builder_api'

const BASE_URL = process?.env.REACT_APP_PORTAL_HOST;

axios.defaults.baseURL = BASE_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  mode: 'no-cors'
});

const getAll = () => {
  return httpClient.get('/');
};

const get = (id) => {
  return httpClient.get(`/${id}`);
};

const auth = async (email, password) => {

  let userData = await httpClient
    .post(
      '/auth',
      { email, password },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      },
      {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    )
    .catch(function (error) {
      console.log('deu error');
      console.log(error);
    });

  let forms = await getForms();
  if (userData?.data) {
    userData.data.forms = forms;
  }

  return userData;
};

const authInquiry = async (inquiry) => {
  let endpoint = `inquiries/by-acesso`;

  let userData = await httpClient
    .post(
      endpoint,
      inquiry,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      },
      {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    )
    .catch(function (error) {
      console.log('login  error');
      console.log(error);
    });

  return userData?.data;
};

const create = (data) => {
  return httpClient.post('/', data);
};

const getForms = async () => {
  return await formBuilderApiService.getAll();
};

const update = (id, data) => {
  return httpClient.put(`/${id}`, data);
};

const remove = (id) => {
  return httpClient.delete(`/${id}`);
};

const removeAll = () => {
  return httpClient.delete('/');
};

const findByTitle = (title) => {
  return httpClient.get(`/?title=${title}`);
};

const synchronize = async (userEmail) => {
  return await formBuilderApiService.synchronize();
};

const synchronizeOne = async (formName, body, key) => {
  return await formBuilderApiService.synchronizeOne(formName, body, key);
};

const synchronizeMany = async (rowData) => {
  return await formBuilderApiService.synchronizeMany(rowData);
};

const removeOne = async (formName, key) => {
  return await formBuilderApiService.removeOne(formName, key);
};

const removeMany = async (rowData) => {
  return await formBuilderApiService.removeMany(rowData);
};

const countSavedData = async () => {
  return await formBuilderApiService.countSavedData();
};

const getForm = async (formName) => {
  return await formBuilderApiService.getForm(formName);
};

export const userApiService = {
  getAll,
  get,
  auth,
  authInquiry,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  synchronize,
  synchronizeOne,
  synchronizeMany,
  removeOne,
  removeMany,
  countSavedData,
  getForms,
  getForm
};
