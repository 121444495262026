import React from 'react';
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import style from '../../themes/styles/geral';

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1009;
  border-color: red;
`;

const useStyle = makeStyles(style);
export default function Spinner(props) {
  const classes = useStyle();
  const loading = props?.loading || true;
  let color = "#d0d6d6";
  const { setShow } = props;

  return (
    <Box onClick={() => { setShow(false) }} className={`${loading ? classes.spinnerCurtainOn : classes.spinnerCurtainOff} h-100 w-100`} >
      <RingLoader color={color} loading={true} css={override} size={600} />
    </Box>
  );
};
