import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuSharp from '@material-ui/icons/MenuSharp';
import Toast from '../../components/Toast'
import style from '../../themes/styles/geral';
import logo from '../../assets/images/menu_logo.png';
import logoMd from '../../assets/images/SNIEA.png';
import Menu from '../Menu'
import Formularios from '../Formularios'
import Sincronizacao from '../Sincronizacao'
import MapViewer from '../MapViewer'
import { Col, Row } from 'react-bootstrap';
import minagrip_s from '../../assets/images/minagrip-s.png';
import {
  isMapOpen
}
  from '../../services/database.service';
import { getPolygonData } from '../../assets/js/geocadLib';


const useStyle = makeStyles(style);

export default function MapScreen(props) {
  const classes = useStyle();
  const [mapOpen, setMapOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('error');
  const [screen, setScreen] = useState(null);
  const [currentMapButton, setCurrentMapButton] = useState(null)

  const { logged, setLogged } = props;
  
  const handleOpen = () => {
    if (activeScreen === "menu") {
      handleClose();
    } else {
      setActiveScreen("menu");
      handleScreen("menu");
    }
  }

  const handleClose = () => {
    setActiveScreen(null);
  }

  const handleScreen = (scrn = null) => {
    scrn = scrn || activeScreen;
    setActiveScreen(scrn);
    switch (scrn) {
      case 'menu': return handleMenu();
      case 'formlist': return handleFormList();
      case 'sincronizacao': return handleSincronizacao();
      case 'mapa': return setMapOpen(true);
      default: return null;
    }
  }

  const handleFormList = () => {
    setScreen(<Formularios showMsg={showMsg} setCurrentMapButton={setCurrentMapButton} openMap={() => setMapOpen(true)} handleClose={handleScreen} changeScreen={handleScreen} logged={logged} />)
  }

  const handleSincronizacao = () => {
    setScreen(<Sincronizacao showMsg={showMsg} openMap={() => setMapOpen(true)} handleClose={handleClose} changeScreen={handleScreen} logged={logged} />)
  }

  const handleMenu = () => {
    setScreen(<Menu showMsg={showMsg} handleClose={handleClose} changeScreen={handleScreen} />)
  }

  const showMsg = {
    error: (msg) => {
      setAlertShow(true);
      setAlertMsg(msg);
      setAlertType('error');
      removeMsg();
    },

    success: (msg) => {
      setAlertShow(true);
      setAlertMsg(msg);
      setAlertType('success');
      removeMsg();
    },
    
    warning: (msg) => {
      setAlertShow(true);
      setAlertMsg(msg);
      setAlertType('primary');
      // removeMsg();
    }
  }

  useEffect(function () {
    if (!isMapOpen() && currentMapButton) {
      let polygon = getPolygonData();
      fillMapData({ element: currentMapButton, polygon });
    } else fillMapData({ element: currentMapButton });
  }, [currentMapButton])

  const removeMsg = () => {
    setTimeout(() => {
      setAlertShow(false);
    }, 5000)
  }

  const fillMapData = (params = null) => {
    console.log('fiill that map', params)
    const { element = currentMapButton, polygon = getPolygonData() } = params || {};

    if(!element) return false;

    if (polygon) {
      element.parentNode.querySelector('textarea').value = JSON.stringify(polygon)
      element.parentNode.querySelector('textarea').click();
    }

    let infoMaps = element.parentNode.querySelector('.infoMaps');
    let area = infoMaps.querySelector('.area_value');
    let perimetro = infoMaps.querySelector('.perimetro_value');
    area.dataset.value = polygon?.area
    perimetro.dataset.value = polygon?.distance

    area.innerHTML = `<span style='color:red'>Área</span>: ${polygon?.area}`
    perimetro.innerHTML = `<span style='color:red'>Perímetro:</span>: ${Math.round(polygon?.distance * 100) / 100} km`
    if (polygon) infoMaps.classList.remove('d-none');
    else infoMaps.classList.add('d-none');

  }

  return (
    <>
      {alertShow ? <Toast onClose={setAlertShow} type={alertType} msg={alertMsg} /> : null}
      {mapOpen ? <MapViewer showMsg={showMsg} fillMapData={fillMapData} closeMap={() => setMapOpen(false)} /> : null}
      <Row className={`${classes.header} d-flex position-relative w-100 p-0 m-0`} >
        <img
          className={`${classes.menuLogo} d-md-none h-100 p-0 m-0`}
          src={logo}
          alt=''
        />
        <img
          className={`${classes.menuLogo} d-none h-100 d-md-block p-0 m-0`}
          src={logoMd}
          alt=''
        />

        <Box className={`${classes.userContainer} h-100 d-flex flex-row-reverse d-md-block m-0 p-0 position-absolute end-0`} >
          <Box className={`position-absolute w-auto d-flex flex-row end-0 h-100 p-0`}>
            <Button className={`${classes.menuButton} h-100 p-0`} >
              {logged.nome}
            </Button>
            <Button onClick={handleOpen} className={`${classes.menuButtonRight} h-100 p-0`} >
              <MenuSharp />
            </Button>
          </Box>
        </Box>
      </Row>
      <Row className={`${classes.body} d-flex flex-row w-100 p-0 m-0 flex-fill `} >
        <Col xs={12} md={12} className={`${classes.mapContainer} position-relative h-100 m-0 p-0`}  >
          <div className={`${classes.mapCoverDiv} ${activeScreen ? '' : ' d-none '} position-absolute start-0 top-0 w-100 h-100 m-0 p-0`} >
            {/* {handleScreen()} */}
            {screen}
          </div>
          <div className={`${classes.mapDiv} m-0 p-0`} >
            <img
              className={`${classes.minagripS} `}
              src={minagrip_s}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};