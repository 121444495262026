import React, {
  useState,
  useEffect,
} from 'react';
import { getFormData, resetFormData } from '../../services/database.service'
import ConfirmBox from '../../components/ConfirmBox'
import { userApiService } from '../../services/api'
import { makeStyles } from '@material-ui/core/styles';
import style from '../../themes/styles/geral';
import Formularios from '../Formularios';
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Autorenew from "@material-ui/icons/Autorenew";
import icones from '../../themes/icones';
const useStyle = makeStyles(style);

export default function Sincronizacao(props) {
  const classes = useStyle();
  const [formList, setFormList] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [currentData, setCurrentData] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [countToSend, setCountToSend] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [callback, setCallback] = useState('sendAll');
  const { openMap, showMsg, logged, changeScreen } = props;

  useEffect(function () {
    getForms();
  }, [])

  useEffect(() => {
    if (Object.values(formList).length <= 0) return false;

    let _data = {};
    let chechedItmes = [];

    if (!checkAll) {
      chechedItmes = Object.values(formList).filter(item => {
        let check = item.checked == true;
        return !check;
      })
    }

    Object.values(formList).map(item => {
      _data[item.id] = item;
      if (!chechedItmes.length > 0 && !chechedItmes.includes(item)) _data[item.id].checked = checkAll;
    })

    setFormList(_data);
    setCountToSend(checkAll ? Object.values(_data).length : 0);
  }, [checkAll])

  async function getForms() {
    let response = getFormData();
    let list = {};

    if (response) {
      Object.values(response).map(form => {
        Object.keys(form.content).map(key => {
          let data = form.content[key]._data;
          let dataText = { ...data };
          let createdAt = parseDate(dataText._Metadata.createdAt);
          let dt = {
            createdAt: createdAt,
            name: dataText?._Metadata?.formulario,
            nome: form.form,
            checked: false,
            modelo: `${form.form}.html`,
            id: key,
            data: dataText,
            send: async () => {
              setCurrentData({ nome: form.form, modelo: `${form.form}.html`, key: key, data: form.content[key]._data });
              setCallback('send');
              setConfirmMsg('Deseja realmente enviar este registo?')
              setShowConfirm(true);
            },
            edit: () => {
              setEditMode(true);
              setCurrentData({ nome: dataText?._Metadata?.formulario, modelo: `${form.form}.html`, key: key, data: form.content[key]._data });

            },
            remove: async () => {
              setCurrentData({ nome: form.form, modelo: `${form.form}.html`, key: key, data: form.content[key]._data });
              setCallback('remove');
              setConfirmMsg('Deseja realmente remover este registo?')
              setShowConfirm(true);
            },
          }

          list[key] = dt;
          return list;
        });

        setCountToSend(0);
        return form
      })
    }

    setFormList(list);
  }

  const send = {
    send: async (confirm) => {
      if (confirm) {
        let resp = await userApiService.synchronizeOne(currentData.nome, currentData.data, currentData.key);
        console.warn('send one', resp);
        if (resp) {
          showMsg.success('Sincronização concluída com sucesso!');
          getForms();
          removeCheckedFormList();
        } else {
          showMsg.error('Falha na Sincronização! Tente novamente mais tarde!');
        }
      }
      resetAllData()
    },
    remove: async (confirm) => {
      if (confirm) {
        let resp = await userApiService.removeOne(currentData.nome, currentData.key);
        if (resp) {
          showMsg.success('Registo removido com sucesso!');
          getForms();
          removeCheckedFormList();
        } else {
          showMsg.error('Falha na remoção! Tente novamente mais tarde!');
        }
      }
      resetAllData()
    },
    deleteAll: async (confirm) => {
      if (confirm) {
        await userApiService.removeMany(formList);
        showMsg.success(`Foram removidos ${countToSend} registos.`);
        removeCheckedFormList();
      } else {
        showMsg.error('Falha na remoção! Tente novamente mais tarde!');
      }
    },
    sendAll: async (confirm) => {
      if (confirm) {
        await userApiService.synchronizeMany(formList);
        showMsg.success(`Foram enviados ${countToSend} registos.`);
        removeCheckedFormList();
      } else {
        showMsg.error('Falha na Sincronização! Tente novamente mais tarde!');
      }
      resetAllData();
    },
  }

  const removeCheckedFormList = () => {
    console.error('vai limpar')
    let _data = {};
    Object.values(formList).map(form => {
      if (!form.checked) {
        _data[form.id] = form;
      }
    })

    setFormList(_data);
    resetAllData();
  }

  const resetAllData = () => {
    if (Object.values(formList).length <= 0) {
      resetFormData();
    }
  }

  const parseDate = (dateObj) => {
    if (!dateObj) return '-';
    let dt = dateObj.split('T')[0].split('-');
    let horario = dateObj.split('T')[1].split('.')[0];
    return `${dt[2]}/${dt[1]}/${dt[0]} ${horario}`;
  }

  const handleClose = () => {
    getForms();
    setCurrentData(false);
    setEditMode(false);
  }

  const addToSend = ({ item, checked }) => {

    formList[item.id].checked = !formList[item.id].checked;
    if (!formList[item.id].checked) {
      setCheckAll(false);
    }

    handleSetCountToSend();
  }


  const handleSetCountToSend = () => {
    let count = Object.values(formList).filter(item => {
      return item.checked == true;
    })

    if (count.length === Object.values(formList).length) setCheckAll(true);
    setCountToSend(count.length);

  }

  const handleFormMenus = () => {
    return (
      <Box className={`${classes.menuContainer} h-100 w-100 p-0 m-0`} >
        {confirmBox()}
        <Box className={`${classes.formList} flex-fill`} >
          <Box className={`${classes.formsTitleContainer2} position-relative`} >
            <div>
              <h6 className={classes.formsTitle}>Dados</h6>
              <label className={classes.formsSubTitle}>Preenchidos</label>
            </div>
            <Box className={`${classes.rightBox} position-relative`} >
              <Button disabled={countToSend <= 0} onClick={async () => {
                setShowConfirm(true);
                setConfirmMsg(`Deseja realmente sincronizar os registos seleccionados?`);
                setCallback('sendAll');
              }}
                className={`${classes.topIcon3} ${classes.red} d-none p-0 position-absolute end-0 `} >
                Enviar {countToSend ? (countToSend === Object.values(formList).length ? 'Todos' : `(${countToSend})`) : ''}
              </Button>
              <Delete disabled={countToSend <= 0} onClick={() => {
                setShowConfirm(true);
                setConfirmMsg(`Deseja realmente remover os registos seleccionados?`);
                setCallback('deleteAll');
              }}
                className={`${classes.topIcon} ${classes.red} h-100 w-25 p-0`}
                src={icones.remove} />
              <Autorenew disabled={countToSend <= 0} onClick={() => {
                setShowConfirm(true);
                setConfirmMsg(`Deseja realmente sincronizar os registos seleccionados?`);
                setCallback('sendAll');
              }}
                className={`${classes.topIcon} ${classes.green} h-100 w-25 p-0`}
                src={icones.remove} />
            </Box>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.formListText} >
                  <Checkbox checked={checkAll} color={"default"} size={"small"} onClick={(e) => setCheckAll(e.target.checked)} />
                </TableCell>
                <TableCell className={classes.formListText} >Data</TableCell>
                <TableCell className={classes.formListText} >Nome</TableCell>
                <TableCell className={classes.formListText} >Acções</TableCell>
              </TableRow>
              {Object.values(formList).map((menu, key) => {

                return (
                  <TableRow key={menu.id} onClick={menu.onclick} className={key % 2 === 0 ? classes.formListButtonDark : classes.formListButton} >
                    <TableCell className={classes.formListText2} >
                      <Checkbox checked={menu.checked} className={'checkBtn'} key={menu.id} color={"default"} size={"small"} onClick={(e) => addToSend({ item: menu, checked: e.target.checked })} />
                    </TableCell>
                    <TableCell className={classes.formListText2} >{menu.createdAt}</TableCell>
                    <TableCell className={classes.formListText2} >{menu.name}</TableCell>
                    <TableCell className={classes.formListTextFlexRow} >

                      <Autorenew
                        onClick={() => menu.send()}
                        className={`${classes.menuLogoNoMargin} ${classes.green} h-100 w-25 p-0`}
                        src={icones.sincronizacao}
                      />
                      <Edit onClick={() => menu.edit()}
                        className={`${classes.menuLogoNoMargin} ${classes.blue} h-100 w-25 p-0`}
                        src={icones.remove} />
                      <Delete onClick={() => menu.remove()}
                        className={`${classes.menuLogoNoMargin} ${classes.red} h-100 w-25 p-0`}
                        src={icones.remove} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>)
  }

  const handleEdit = () => {
    return (
      <Box className={`${classes.menuContainer} h-100 w-100 p-0 m-0`} >
        <Formularios currentData={currentData} showMsg={showMsg} openMap={() => openMap(true)} handleClose={handleClose} changeScreen={changeScreen} logged={logged} />
      </Box>)
  }

  const handleResult = (result) => {
    try {
      setShowConfirm(false);
      send[callback](result);
    } catch (error) {
      console.error(error);
    }
  }

  const confirmBox = () => {
    return (<ConfirmBox msg={confirmMsg} show={showConfirm} handleResult={handleResult} />)
  }

  return (editMode ? handleEdit() : handleFormMenus());
};