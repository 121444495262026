import * as L from 'leaflet'
import 'leaflet-draw'
import 'leaflet-gps'
import 'leaflet.offline'
import {
  setTempPolygon,
  getTempPolygonCoords,
  setDrawingPolygon,
  getDrawingPolygon,
  isMarkMissionMode,
  setGeoPosition,
  getGeoPosition,
  setMission,
  getMission,
  getPolygonData as __getPolygonData,
  addCoordsToTempPolygon,
  resetTempPolygon,
  resetCurrentPolygon,
  deleteGeoPosition,
  setPolygon
}
  from '../../services/database.service'

var LastLat,
  LastLng,
  featureGroup,
  marker,
  circle,
  walkingPoint = []

const province = {
  'Benguela': '#E31A1C',
  'Bengo': '#800026',
  'Bié': '#82E0AA',
  'Cabinda': '#FD8D3C',
  'Cuando Cubango': '#BB8fE',
  'Cuanza Sul': '#F5B041',
  'Cuanza Norte': '#F5B051',
  'Cunene': ' #148F77',
  'Huí­la': ' #784212 ',
  'HuÃ­la': ' #784212 ',
  'Huambo': '#FC4E2A',
  'Luanda': '#BD0026',
  'Lunda Norte': '#F4D03F ',
  'Lunda Sul': '#2874A6',
  'Malanje': ' #7E5109',
  'Moxico': ' #283747',
  'Namibe': '#E6B0AA',
  'Uí­ge': '#FED976',
  'UÃ­ge': '#FED976',
  'Zaire': '#FEB24C'
}

var map = {};
var zoomSteps = [12, 15, 17, 19];
var currentZoomValue = zoomSteps[0];

var polygon_options = {
  showArea: true,
  shapeOptions: {
    stroke: true,
    color: '#6e83f0',
    weight: 4,
    opacity: 0.5,
    fill: true,
    fillColor: null, //same as color by default
    fillOpacity: 0.2,
    clickable: true
  }
}

var polygonDrawer;

function getColor(feature) {
  if (province[feature.properties.NAME_1] || province[feature.properties.NAME_1] === '') {
    return province[feature.properties.NAME_1]
  }
  return '#FFEDA0'
}

function style(feature, styleColor, tam) {
  console.log('Density: ', feature.properties.NAME_1)
  return {
    fillColor: getColor(feature),
    weight: tam !== undefined ? tam : 2,
    opacity: 1,
    color: styleColor !== undefined ? styleColor : 'white',
    dashArray: '3',
    fillOpacity: 0.7
  }
}

export const getFeatureGroup = (marker) => {
  let featureGroup = L.featureGroup([marker]).addTo(map)
  moveToPosition(featureGroup)

  return featureGroup
}

export const setMarker = (params = {}) => {
  let { map, latlng, text = 'Aqui' } = params;
  L.marker(latlng).addTo(map)
    .bindPopup(`${text}`).openPopup()
}

export const locate = (params = {}) => {
  console.error('locate ON')
  map.locate({ setView: true, watch: true, maxZoom: params?.maxZoom || Number(window.geocadProps.maxZoom) + 2 });
}

export const setCircle = (params = {}) => {
  let { latlng, radius, mapa = map } = params;
  L.circle(latlng, radius).addTo(mapa)
}

export function onLocationFound(e) {

  LastLat = e.latlng.lat
  LastLng = e.latlng.lng

  if (marker) {
    map.removeLayer(marker)
  }
  if (circle) {
    map.removeLayer(circle)
  }

  featureGroup = getFeatureGroup(marker)

  document.getElementById('stop').addEventListener('click', (e) => {
    map.stopLocate()
    e.preventDefault()

    if (marker) {
      map.removeLayer(marker)
    }
    if (circle) {
      map.removeLayer(circle)
    }
    let polygon = L.polygon(walkingPoint).addTo(map)
    localStorage.setItem('geoJSON', JSON.stringify(polygon.toGeoJSON()))
  })
}

// var mapServer = '';
var mapServer = 'https://api.mapbox.com/styles/v1';
// var mapServer = 'https://api.mapbox.com/styles/v1';
window.mapServer = mapServer;
var mapUrl = '';
var mapMode = 'mapbox/streets-v11';
var accessToken = 'pk.eyJ1Ijoid2FsdGVyYW5nb2xhciIsImEiOiJja3JlcjR1bW4wMG02Mm9xbnJoZnA5dDczIn0.EiaiP9Z8IfNfl96y-MryGw';

export function loadMap(params = {}) {

  const { id = window.geocadProps.id } = params;
  // if (params.mapMode) mapMode = params.mapMode;
  console.log('params_', params)

  //map = L.map(id, {
  map = L.map(id, {
    center: window.geocadProps.center,
    zoom: 20 || window.geocadProps.zoom,
    drawControl: true
  })

  mapUrl = `${mapServer}/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`;
  L.tileLayer.offline(mapUrl, {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: window.geocadProps.maxZoom,
    zoom: window.geocadProps.zoom,
    minZoom: window.geocadProps.minZoom,
    id: mapMode,
    tileSize: 512,
    zoomOffset: -1,
    accessToken: accessToken
  }).addTo(map, { style: style })

  // setMarker({ map, latlng: window.geocadProps.center, radius: 2708 });

  map.on('click', function (e) {
    console.log('click event')
    navigator.geolocation.getCurrentPosition(function (data) {
      setTimeout(function () {
        console.log('mostra em click event', data)
      }, 1000)
    })
    console.log(`Coordenadas `, map, map._lastCenter?.lat, map._lastCenter?.lng)
  })

  map.on('zoom', (e) => {

    try {
      console.log('zoom event')
      let newZoom = e.target._zoom;
      if (currentZoomValue < newZoom) {
        handleZoomIn();
      } else {
        handleZoomOut();
      }
      currentZoomValue = newZoom;
      return false;
    } catch (error) {
    }
  })

  map.on('locationfound', (e) => {

    try {
      // let { lat, lng } = e.latlng
      // const { latitude, longitude, accuracy } = e;

      // console.log((movQtde++), 'moveu-se', lat, lng)
      // setNewLocation({ latitude, longitude, accuracy });

      // if (lat !== LastLat && lng !== LastLng) {
      //   onLocationFound(e)
      // } else {
      //   moveToPosition(featureGroup)
      // }

      // handleZoomOut();
    } catch (error) {

    }
  })

  map.on('resize', (e) => {

    try {
      if (e.target._lastCenter) savePosition(e.target._lastCenter);

    } catch (error) {
      console.error('resize', error)
    }
  })

  map.on('load', (e) => {

    try {
      savePosition(e.target._lastCenter);
      console.log('load', e.target._lastCenter)

    } catch (error) {
      console.error('load', error)
    }
  })

  map.on('move', (e) => {

    try {
      let { lat, lng } = map._lastCenter || {};
      savePosition(e.target._lastCenter);
      // console.log('move', e)

    } catch (error) {
      console.error('move', error)
    }
  })

  map.on(L.Draw.Event.CREATED, function (e) {
    try {

      let points = e.layer?._latlngs[0] || [];
      let newArray = [];
      for (let p of points) {
        let _point_to_save = [p.lat, p.lng];
        newArray.push(_point_to_save);
      }

      let area = 0;
      for (let island of e.layer.getLatLngs()) {
        // If the polygon is non-contiguous, access the island
        if (island.length < 2) {
          island = island[0]
        }
        // Sum the area within each "island"
        area += L.GeometryUtil.geodesicArea(island);
      }
      if (isMarkMissionMode()) {
        setMission({ coords: newArray, area: area })
        showPolygon({ color: 'orange' })
      } else {
        setTempPolygon({ coords: newArray, area: area });
        showPolygon()
      }
    } catch (error) {

    }
  });

  map.addControl(new L.Control.Gps({
    transform: function (realGps) {
      setGeoPosition({
        latitude: realGps.lat,
        longitude: realGps.lng,
        accuracy: getGeoPosition().accuracy
      })
      return realGps;
    }
  }));

  showPolygon();

  // setUpGPSTracking();

  map.locate({
    setView: true,
    maxZoom: window.geocadProps.maxZoom,
    watch: true,
    enableHighAccuracy: true
  })

  setZoomEvents();

  return map
}

var polygons = []

export function showPolygon(params = {}) {
  try {
    let { color = 'red' } = params;
    var coords = getTempPolygonCoords();
    let distance = 0;
    let area = 0;

    if (coords?.length > 0) {

      for (let x of coords) {
        let polygon2 = L.polygon(x, { color: color }).addTo(map);
        polygons.push(map.fitBounds(polygon2?.getBounds()));
        distance = calcDistance(polygon2.getLatLngs()[0]);
        area = calcArea(polygon2.getLatLngs()[0]);
        return { area, distance, coords }
      }
    }

    coords = getDrawingPolygon();
    if (coords?.length > 0) {
      let __polygon = L.polygon(coords, { color: 'blue' }).addTo(map);
      polygons.push(map.fitBounds(__polygon?.getBounds()));
      distance = calcDistance(__polygon?.getLatLngs()[0]);
      area = calcArea(__polygon?.getLatLngs()[0]);
      return { area, distance, coords }
    }

    coords = getMission()?.coords;
    if (coords?.length > 0) {
      let m__polygon = L.polygon(coords, { color: 'orange' }).addTo(map);
      polygons.push(map.fitBounds(m__polygon.getBounds()));

      let center = m__polygon.getBounds().getCenter();

      map.flyTo(center, 14);
      setMarker({ map, latlng: center, radius: 1048, text: 'Local da missão' });
      distance = calcDistance(m__polygon?.getLatLngs()[0]);
      area = calcArea(m__polygon?.getLatLngs()[0]);
      return { area, distance, coords }
    }

  } catch (error) {
    console.error('error2', error)
  }
}

export function getPolygonData(params = {}) {
  try {
    let { color = 'red' } = params;
    var coords = __getPolygonData()?.coords;
    let distance = 0;
    let area = 0;

    if (coords?.length > 0) {

      let polygon2 = L.polygon(coords, { color: color }).addTo(map);
      polygons.push(map.fitBounds(polygon2?.getBounds()));
      distance = calcDistance(polygon2.getLatLngs()[0]);
      area = calcArea(polygon2.getLatLngs()[0]);
      return { area, distance, coords }
    }

  } catch (error) {
    console.error('error2', error)
  }
}

function calcDistance(arr = []) {
  let distance = 0;
  if (Array.isArray(arr)) {
    let point1 = arr[0];
    // console.log('point1', point1);
    for (let i = 1; i < arr.length; i++) {
      distance += point1.distanceTo(arr[i]);
      point1 = arr[i];
    }
  }
  return distance;
}

function calcArea(arr = []) {
  try {
    return `${L.GeometryUtil.geodesicArea(arr) / 1000} ha`;
    // return `${L.GeometryUtil.geodesicArea(arr) / 1000} ha (${Math.round(L.GeometryUtil.geodesicArea(arr) * 100) / 100} km2)`;
  } catch (error) {

  }
  return 0;
}

export function savePosition(params = {}) {
  try {
    if (!params) return;
    let { lat, lng } = params

    setGeoPosition({
      latitude: lat,
      longitude: lng,
      accuracy: getGeoPosition().accuracy
    });
  } catch (error) {
    console.error('error2', error)
  }
}

function lon2tile(lon, zoom) { return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom))); }
function lat2tile(lat, zoom) { return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom))); }

export function fetchMapPolygon() {
  try {
    var coords = getTempPolygonCoords();
    if (Array.isArray(coords[0])) {
      coords[0].map(async (crd) => {
        let x = lon2tile(crd[1], currentZoomValue);
        let y = lat2tile(crd[0], currentZoomValue);
        let url = mapUrl.replace('{z}', currentZoomValue).replace('{y}', y).replace('{x}', x).replace('{accessToken}', accessToken).replace('{id}', mapMode);
        fetch(url);
      })
    }

  } catch (error) {
    //console.error('error2', error)
  }
}

var gpsTracking = null;

export function setUpGPSTracking(handle = null) {
  try {
    window.navigator.geolocation.clearWatch(gpsTracking);
    gpsTracking = window.navigator.geolocation.watchPosition(function (position) {
      console.warn(
        `Your coordinate is latitude: ${position.coords.latitude}​​​​​​​​ 
        longitude: ${position.coords.longitude}​​​​​​​​ 
        accuracy: ${position.coords.accuracy}​​​​​​​​`
      )

      let oldCoords = getGeoPosition();

      setGeoPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy
      })

      if (handle && oldCoords?.latitude !== position.coords.latitude && oldCoords?.longitude !== position.coords.longitude) {
        handle('Aguarde um instante por favor...')
      }
    }, function (error) {
      console.error('watch position', error);
    }, {
      enableHighAccuracy: true
    });

  } catch (error) {
    console.error('error at setUpGPSTracking', error)
  }
}


export function setNewLocation(params = {}) {
  try {
    const { handle = null, auto = false } = params;

    let currentCoords = getGeoPosition();

    setUpGPSTracking(handle);
    if (!currentCoords) {
      return handle ? { success: 'warning', msg: 'Aguarde a precisão do GPS por favor...' } : false;
    }

    let temp_polygon = getTempPolygonCoords();

    if (temp_polygon) {
      let coords = Array.from(temp_polygon)[0];
      coords = coords[coords?.length - 1];

      if (currentCoords.latitude === coords[0] && currentCoords.longitude === coords[1]) {
        return handle ? { success: 'warning', msg: 'Aguarde a precisão do GPS por favor...' } : false;
      };
    }

    const { latitude, longitude, accuracy } = currentCoords;
    addCoordsToTempPolygon({ latitude, longitude, accuracy })
    showPolygon();
    return handle ? { success: 'success', msg: 'GPS sincronizado!' } : true;
  } catch (error) {
    // return handle ? { success: 'error', msg: 'Erro ao capturar ponto!' } : false;
  }
}

export function handleGPSTracking(dados = {}) {
  try {
    const { latitude, longitude } = dados;
    setDrawingPolygon([latitude, longitude]);
    showPolygon();
  } catch (error) {
    console.log('error', error)
  }
}

export function stopGPSTracking() {
  try {
    clearInterval(gpsTracking);
  } catch (error) {
    console.log('error', error)
  }
}

var zoomInCommand, zoomOutCommand;

export function setZoomEvents() {
  try {
    console.log('vai setar zoom in')
    let timer = setInterval(function () {

      if (document.getElementsByClassName('leaflet-control-zoom-in')) {

        zoomInCommand = document.getElementsByClassName('leaflet-control-zoom-in')[0];
        zoomOutCommand = document.getElementsByClassName('leaflet-control-zoom-out')[0];

        if (zoomInCommand) {
          zoomInCommand.addEventListener('click', () => {
            document.getElementsByClassName('leaflet-control-zoom-in')[0].classList.remove('leaflet-disabled')
            document.getElementsByClassName('leaflet-control-zoom-out')[0].classList.remove('leaflet-disabled')
            handleZoomIn();
          })
          zoomInCommand.classList.remove('leaflet-disabled')
        }

        if (zoomOutCommand) {
          zoomOutCommand.addEventListener('click', () => {
            document.getElementsByClassName('leaflet-control-zoom-in')[0].classList.remove('leaflet-disabled')
            document.getElementsByClassName('leaflet-control-zoom-out')[0].classList.remove('leaflet-disabled')
            handleZoomOut();
          })
          zoomOutCommand.classList.remove('leaflet-disabled')
        }

        clearInterval(timer);
      }
    }, 3000)
  } catch (error) {
    console.log('error1', error)
  }
}

export function handleZoomIn() {
  try {
    let zoom = nextZoomStep();
    if (zoom === map._layersMaxZoom) return;
    console.log(map.getZoom(), ' =>', zoom)
    map._layersMaxZoom = zoom;
    map._layersMinZoom = zoom;
    if (map?._lastCenter) map.setView(Object.values(map._lastCenter), zoom);
  } catch (error) {
    console.log('error1', error)
  }
}

export function handleZoomOut() {
  try {
    let zoom = previousZoomStep();
    if (zoom === map._layersMinZoom) return;
    console.log(map.getZoom(), ' =>', zoom)
    map._layersMaxZoom = zoom;
    map._layersMinZoom = zoom;
    map.setView(Object.values(map._lastCenter), zoom);
    //console.log('target zoom', zoom)
  } catch (error) {
    console.log('error1', error)
  }
}

export function nextZoomStep() {
  try {
    for (let i = 0; i < zoomSteps.length; i++) {
      if (currentZoomValue < zoomSteps[i]) {
        return zoomSteps[i];
      }
    }
  } catch (error) {
    console.log('error', error)
  }
  return currentZoomValue;
}

export function previousZoomStep() {
  try {
    for (let i = zoomSteps.length - 1; i >= 0; i--) {
      if (currentZoomValue > zoomSteps[i]) {
        return zoomSteps[i];
      }
    }
  } catch (error) {
    console.log('error', error)
  }
  return currentZoomValue;
}

export async function getCurrentPosition(data) {
  try {
    let it = await window.navigator.geolocation.getCurrentPosition(function (data) {
      const { latitude, longitude } = data.coords;
      setDrawingPolygon([latitude, longitude]);
      showPolygon();
    }, function (error) { console.log('error', error) }, {
      enableHighAccuracy: true,
      timeout: 50000,
      maximumAge: 0
    });
  } catch (error) {
    console.log('error', error)
  }
}

export const moveToPosition = (featureGroup) => {
  let bounds = featureGroup.getBounds()
  map.fitBounds(bounds, { maxZoom: window.geocadProps.maxZoom })

  if (bounds) {
    console.log('quem é o L')
    // Initialize the FeatureGroup to store editable layers
    polygonDrawer = new L.Draw.Polygon(map, polygon_options);
    polygonDrawer.enable()
    let layerClick = document.querySelector('.leaflet-draw-tooltip-single')
    console.log(layerClick.innerHTML)
    // document.getElementById('testTarget')
    mouseClick(layerClick)

    //console.log(Object.getOwnPropertyNames(polygonDrawer))
    // walkingPoint.push([polygonDrawer._currentLatLng.lat, polygonDrawer._currentLatLng.lng])
    // console.log(polygonDrawer._markers)
    // polygonDrawer.drawstart()
  }
}

export let mouseClick = (elem) => {
  // console.log('clicou')
  // let event = new MouseEvent('click', {
  //   bubbles: true,
  //   cancelable: true,
  //   clientX: 20,
  //   view: elem.ownerDocument.defaultView
  // })
  // event.preventDefault()
  // console.log('clicou 2')

  polygonDrawer = new L.Draw.Polygon(map, polygon_options)
  polygonDrawer.enable()

  let setTime = setTimeout(() => {

    let btn = document.createElement('buttom')
    btn.onclick = () => {
      //alert('clicou-me!')
    }
    btn.click()
    clearTimeout(setTime)
  }, 3000)
  // let camceled = !elem.dispatchEvent(event)
}
