import { userApiService } from './api';
import { formBuilderApiService } from './form_builder_api';
import { databaseService } from './database.service';

export const signIn2 = async (data) => {

  let response = await userApiService.authInquiry(data);

  if (response) {
    if (response.success) {
      if (response.data.includes("NOT_FOUND")) return false;
      let userData = response.data[0];
      databaseService.saveLogged(userData);
      handleFormsOffline(userData);
    }
    return response;
  } else {
    console.warn('offline')
    let users = databaseService.getUsers();
    let offlineLogged = Object.values(users).filter(usr => {
      return usr?.acesso === data?.acesso;
    })
    if (offlineLogged.length > 0) {
      await databaseService.saveLogged(offlineLogged[0]);
      return { success: true, data: offlineLogged[0] };
    }
  }
  return false;
}

const handleFormsOffline = async (userData) => {
  var assets = [];
  if (Array.isArray(userData.formularios)) {
    for (let i = 0; i < userData.formularios.length; i++) {
      const form = userData.formularios[i];
      let resp = await userApiService.getForm(form.modelo);
      assets = assets.concat(resp.css).concat(resp.js);
    }
    assets = [...new Set(assets)].filter(e => e !== undefined && e !== '');

    assets.map(_asset => {
      formBuilderApiService.getFullAdress(_asset);
    })
  }
}

export const isLogged = async () => {
  return await databaseService.isLogged();
}

const authService = { isLogged }

export default authService;