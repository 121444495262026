import React, { useState, useEffect } from 'react';

import authService from '../../services/auth';
import LoginScreen from '../Login';
import MissionScreen from '../Mission';
import { Row } from 'react-bootstrap';

export default function HomeScreen(props) {

    const [logged, setLogged] = useState(false);

    useEffect(() => {

        async function fetchData() {
            console.log('SNIEAP Mobile 1.0.0')
            let resp = await authService.isLogged()
            if(resp) setLogged(resp)
        }

        fetchData();
    }, [setLogged]);

    const handleChild = () => {
        return (
            <Row className={`w-100 vh-100 p-0 m-0 d-flex flex-column justify-content-start`} >
                {logged ? (<MissionScreen logged={logged} setLogged={setLogged} />) : (<LoginScreen setLogged={setLogged} />)}
            </Row>
        )
    };

    return handleChild()
};