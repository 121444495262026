import { database } from '../database';
const index_users = 'users';
const index_logged = 'logged';
const index_position = 'geo_position';
const index_polygon = 'polygon';
const index_temp_polygon = 'temp_polygon';
const index_current_polygon = 'current_polygon';
const index_mark_mission = 'mark_mission';
const index_mission = 'mission';
const index_drawing_polygon = 'drawing_polygon';
const index_form_data = 'form_data';
const index_map_open = 'map_open';
const index_map_offline = 'map_offline';
const db = new database();

export const saveLogged = (logged) => {

  if (!logged) return false;

  let users = getUsers();
  users = Object.values(users).filter(usr => {
    return usr.acesso === logged.acesso;
  })

  db.addCollectionObject(index_users, logged.id, logged)
  db.set(index_logged, logged)

  return logged;
}

export const isLogged = () => {
  return db.get(index_logged);
}

export const logout = () => {
  console.log('logout')
  // db.delete(index_users);
  return db.delete(index_logged);
}

export const setGeoPosition = (data) => {
  return data ? db.set(index_position, data) : false;
}

export const setCurrentPolygon = (data) => {
  return data ? db.set(index_current_polygon, data) : false;
}

export const getCurrentPolygon = () => {
  return db.get(index_current_polygon);
}

export const resetCurrentPolygon = () => {
  resetTempPolygon();
  resetPolygon();
  return db.delete(index_current_polygon);
}

export const isMarkMissionMode = () => {
  return db.get(index_mark_mission);
}

export const setMission = (data) => {
  disableMarkZoneMode();
  return data ? db.set(index_mission, data) : false;
}

export const getMission = () => {
  return db.get(index_mission);
}

export const enableMarkZoneMode = (data) => {
  return data ? db.set(index_mark_mission, data) : false;
}

export const disableMarkZoneMode = () => {
  return db.delete(index_mark_mission);
}

export const getGeoPosition = () => {
  return db.get(index_position);
}

export const deleteGeoPosition = () => {
  return db.delete(index_position);
}

export const setPolygon = (name) => {
  let temp = db.get(index_temp_polygon);
  if (!name || !temp) return false;
  let rowData = { name: name, polygon: db.get(index_temp_polygon) }
  const polygon = getPolygon();
  let found = false;
  if (polygon) {
    Object.values(polygon).map(pol => {
      if (pol.name === rowData.name) {
        pol.polygon = rowData.polygon;
        found = true;
        return pol;
      }
    })
  }
  if (found) {
    db.set(index_polygon, polygon);
  } else {
    db.addCollectionObject(index_polygon, false, rowData);
  }
  db.delete(index_temp_polygon);
  return true;
}

export const setTempPolygon = (data) => {
  if (!data?.type) data.type = 'polygon';
  //console.log('poly data', data)
  if (isMarkMissionMode()) {
    db.set(index_mission, data);
  }
  return data ? db.addCollectionObject(index_temp_polygon, false, data) : false;
}

export const setNewLocation = (params) => {
  try {
    if (!params) return false;
    const { latitude, longitude, accuracy } = params || {};
    setGeoPosition({ latitude, longitude, accuracy });
    addCoordsToTempPolygon({ latitude, longitude, accuracy });
  } catch (error) {
    console.error('erro ao salvar na location', error);
  }
}

export const addCoordsToTempPolygon = (params) => {
  try {
    const pos = params || getGeoPosition();
    if (!pos) return false;
    const { latitude, longitude } = pos;
    if (!latitude || !longitude) return false;
    let _point_to_save = [latitude, longitude];
    let oldData = Object.values(getTempPolygon());
    console.error('oldData', oldData, oldData[0]?.coords);
    let newArray = [];
    if (oldData) {
      if (oldData[0]?.coords) {
        Array.from(oldData[0].coords).map(coord => {
          console.log('current', coord);
          let exists = newArray.filter((val => { return val[0] === coord[0] && val[1] === coord[1] })).length;
          if (exists <= 0) newArray.push(coord);
        })
      }
    }

    newArray.push(_point_to_save);
    resetTempPolygon()
    setTempPolygon({ coords: newArray, area: null });
    deleteGeoPosition()
    return true;
  } catch (error) {
    console.error('erro ao salvar na location 2', error)
  }
}

export const getPolygonCoords = () => {
  try {
    let polygons = getPolygon();
    let result = [];

    for (let c in polygons) {
      result.push(polygons[c].coords)
    }

    return result;
  } catch (error) {
    console.log('getPolygonCoords error', error)
  }
}


export const getPolygonData = () => {
  try {

    let polygon = getFirstPolygon().polygon;
    return polygon ? Object.values(polygon)[0] : null;
  } catch (error) {

  }
  return null
}

export const getTempPolygonCoords = () => {
  try {
    let polygons = getTempPolygon();
    if (!polygons) return null;
    let result = [];

    for (let c in polygons) {
      result.push(polygons[c].coords)
    }

    return result || null;
  } catch (error) {
    console.log('getPolygonCoords error', error)
  }
}

export const getPolygon = () => {
  return db.get(index_polygon);
}

export const setMapOpen = (sinal = true) => {
  return sinal ? db.set(index_map_open, { map: true }) : db.delete(index_map_open);
}

export const isMapOpen = () => {
  return db.get(index_map_open);
}

export const setMapOOffline = (data) => {
  return data ? db.set(index_map_offline, data) : db.delete(index_map_offline);
}

export const isMapOffline = () => {
  return db.get(index_map_offline);
}

export const getFirstPolygon = () => {
  let pol = Object.values(db.get(index_polygon));
  pol = Object.values(pol)[0];
  return pol;
}

export const getTempPolygon = () => {
  return db.get(index_temp_polygon);
}

export const setDrawingPolygon = (data) => {
  let old = getDrawingPolygon();
  console.log('old: ', old, data)
  if (!data[0] || !data[1]) return false;

  for (let i of old) {
    if (data[0] === i[0] && data[1] === i[1]) return old;
  }
  return data ? db.addCollectionObject(index_drawing_polygon, false, data) : false;
}

export const getDrawingPolygon = () => {
  let tts = db.get(index_drawing_polygon);
  let result1 = [];

  for (let c in tts) {
    if (tts[c][0] && tts[c][0]) result1.push(tts[c])
  }

  return result1;
}


export const createId = () => {
  return `_${new Date().getTime()}`
}

export const addFormData = (data) => {

  if (data?.key) return editFormData(data);
  let formData = db.get(index_form_data);
  let id = createId();
  let last_key = 1;

  if (formData && data) {
    for (let key of Object.keys(formData)) {
      last_key = Number(key) + 1;
      if (data.form === formData[key].form) {
        formData[key].content[id] = data.content;
        return db.set(index_form_data, formData) || true;
      }
    }
  }

  delete data.key;
  let content = {};
  content[id] = data.content;
  data.content = content;

  if(last_key > 1) {
    formData[last_key] = data;
    return db.set(index_form_data, formData);
  }

  return db.addCollectionObject(index_form_data, last_key, data);
}

export const editFormData = (data) => {


  let formData = db.get(index_form_data);
  if (formData && data) {
    for (let key of Object.keys(formData)) {
      if (data.form === formData[key].form) {
        formData[key].content[data.key] = data.content;
        return db.set(index_form_data, formData) || true;
      }
    }
  }
  return false;
}

export const getFormData = () => {
  return db.getCollectionAll(index_form_data);
}

export const resetFormData = (data) => {
  return data ? db.set(index_form_data, data) : db.delete(index_form_data);
}

export const resetTempPolygon = () => {
  return db.delete(index_temp_polygon);
}

export const resetPolygon = () => {
  return db.delete(index_polygon);
}

export const getUsers = () => {
  return db.get(index_users);
}

export const getFormList = () => {
  let logged = isLogged();
  if (!logged) return [];
  return logged.formularios;
}

export const _setPosition = function (position) {
  console.error('_setPosition from database', position)
  console.log(
    `Your coordinate is latitude: ${position.coords.latitude}​​​​​​​​ 
    longitude: ${position.coords.longitude}​​​​​​​​ 
    accuracy: ${position.coords.accuracy}​​​​​​​​`
  )

  db.setGeoPosition({
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    accuracy: position.coords.accuracy
  });

}

export const databaseService = {
  saveLogged,
  isLogged,
  setGeoPosition,
  getGeoPosition,
  deleteGeoPosition,
  isMarkMissionMode,
  addFormData,
  getFormData,
  resetFormData,
  getTempPolygonCoords,
  resetTempPolygon,
  addCoordsToTempPolygon,
  getFormList,
  setMapOpen,
  isMapOpen,
  getPolygonData,
  setNewLocation,
  getUsers,
  _setPosition
};
