import React, {
  useEffect,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import style from '../../themes/styles/geral';
import {
  isMapOpen
} from '../../services/database.service'
import { getPolygonData } from '../../assets/js/geocadLib';


const useStyle = makeStyles(style);
export default function Myframe(props) {

  const classes = useStyle();
  const { src, openMap, setCurrentMapButton } = props;

  const fillFields = (params = null) => {
    const { element = null, polygon = null } = params;

    if (polygon) {
      element.parentNode.querySelector('textarea').value = JSON.stringify(polygon)
      element.parentNode.querySelector('textarea').click();
    }

    let infoMaps = element.parentNode.querySelector('.infoMaps');
    let area = infoMaps.querySelector('.area_value');
    let perimetro = infoMaps.querySelector('.perimetro_value');
    area.dataset.value = polygon?.area
    perimetro.dataset.value = polygon?.distance

    area.innerHTML = `<span style='color:red'>Área</span>: ${polygon?.area}`
    perimetro.innerHTML = `<span style='color:red'>Perímetro:</span>: ${Math.round(polygon?.distance * 100) / 100} km`
    if (polygon) infoMaps.classList.remove('d-none');
    else infoMaps.classList.add('d-none');
  }

  window.handleMap = (e) => {
    openMap();
    if(setCurrentMapButton){
      setCurrentMapButton(e.target);
    }
  }

  useEffect(() => {
    document.querySelector('.reload')?.click();
  }, [openMap])

  return (<>
    <div className={`${classes.formContainer} flex-fill w-100 m-0`} dangerouslySetInnerHTML={{ __html: src }}>
    </div>
  </>)
};