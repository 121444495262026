import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Toast(props) {

  const { type = '', msg = 'Ocorreu uma operação', onClose } = props || {};

  const sx = { width: '100%', position: 'absolute', zIndex: 90000 };

  const close = () => {
    onClose(false);
  }

  return (
    <Stack className={ type == 'primary' ? 'fixed-bottom' : 'fixed-top'} sx={sx} spacing={2}>
      <Alert variant="filled" severity={type || "success"} onClose={close} >{msg}</Alert>
    </Stack>
  );
};
